import {
    IonAlert, IonBackButton,
    IonButton, IonButtons, IonCardContent, IonCardHeader, IonCardTitle,
    IonCol, IonContent, IonHeader,
    IonInput, IonItem,
    IonLabel,
    IonList, IonListHeader,
    IonPage, IonRadio, IonRadioGroup,
    IonRow, IonToolbar,
} from "@ionic/react";
import React, {useState} from "react";
import Loading from "../../SharedComponents/Loading/Loading";
import './RecoveryPassword.css';
import {v4 as uuidv4} from 'uuid';
import MessageTextError from "../../SharedComponents/MessageTextError/MessageTextError";
import {startRecoveryPassword} from "../../../../Application/RecoveryPassword/StartRecoveryPassword";
import {NumberNotFound} from "../../../../Domain/Services/RecoveryPassword/NumberNotFound.exception";
import {IdentificationNotFound} from "../../../../Domain/Services/RecoveryPassword/IdentificationNotFound.exception";
import {EmailNotFound} from "../../../../Domain/Services/RecoveryPassword/EmailNotFound.exception";
import {NumberEmailNotFound} from "../../../../Domain/Services/RecoveryPassword/NumberEmailNotFound.exception";
import {Trans, useTranslation} from "react-i18next";


const RecoveryPassword = () => {
    const { t } = useTranslation();

    const [dnicifnie, setDnicifnie] = useState('');
    const [telephone, setTelephone] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [showAlert, setShowAlert] = useState<string | boolean>(false);
    const [showError, setShowError] = useState(false);
    const [selected, setSelected] = useState('owner');

    const handleRegister = async (e) => {
        e.preventDefault();

        setIsLoading(true)
        setShowError(false)
        let isError = false

        if (selected === 'owner' && (!telephone || !dnicifnie)){
            isError = true
            setShowError(true)
        }
        if (selected === 'userline' && (!telephone)){
            isError = true
            setShowError(true)
        }

        if (!isError) {
            let textRequest = {
                "id": uuidv4(),
                "telephone": telephone,
                "nifcifnie": dnicifnie
            }

            try {
                const response = await startRecoveryPassword(textRequest)
                setShowAlert(response)
            } catch (e) {
                if (e instanceof NumberNotFound || IdentificationNotFound || EmailNotFound || NumberEmailNotFound) setShowAlert(e.messageError)
            }

        }

        setIsLoading(false)
    }

    return (
        <IonPage id="login-page">
            <IonContent fullscreen>
                <IonHeader>
                    <IonToolbar>
                        <div className="title-card"><IonCardTitle>{t('RecoveryPassword.page-title')}</IonCardTitle></div>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="home" />
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonCardContent>
                    <form noValidate onSubmit={handleRegister}>
                        <IonRadioGroup value={selected} onIonChange={e => {
                            setTelephone('')
                            setDnicifnie('')
                            setShowError(false)
                            setSelected(e.detail.value)
                        }}>
                            <IonRow>
                                <IonCol>
                                    <IonItem lines="none">
                                        <IonLabel>{t('RecoveryPassword.radio-holder')}</IonLabel>
                                        <IonRadio slot="start" className="radio-select" value="owner"></IonRadio>
                                    </IonItem>
                                </IonCol>
                                <IonCol>
                                    <IonItem lines="none">
                                        <IonLabel>{t('RecoveryPassword.radio-user')}</IonLabel>
                                        <IonRadio slot="start" className="radio-select" value="userline"></IonRadio>
                                    </IonItem>
                                </IonCol>
                            </IonRow>
                        </IonRadioGroup>
                        {
                            selected === 'owner' ? RecoveryPasswordOwner(t, dnicifnie, setDnicifnie, telephone, setTelephone) :
                                RecoveryPasswordUserLine(t, telephone, setTelephone)
                        }
                        <IonRow>
                            <IonCol>
                                <IonButton type="submit" expand="block" className="botton-actions">{t('RecoveryPassword.button-forgot')}</IonButton>
                                {showError ? <MessageTextError textMessage={t('RecoveryPassword.text-error-inputsempty')}/> : ''}
                            </IonCol>
                        </IonRow>
                    </form>
                </IonCardContent>
            </IonContent>
            <Loading isLoading={isLoading}/>
            <IonAlert
                isOpen={showAlert !== false}
                onDidDismiss={() => setShowAlert(false)}
                cssClass='my-custom-class'
                header={t('RecoveryPassword.text-title-alert')}
                message={(showAlert as string)}
                buttons={[t('RecoveryPassword.button-alert')]}
            />
        </IonPage>

    );
}

const RecoveryPasswordOwner = (t, dnicifnie, setDnicifnie, telephone, setTelephone) => {

    return (<>
            <IonList>
                <IonListHeader lines="inset" className="title-subheader">
                    <h3 className="list-header"><Trans i18nKey="RecoveryPassword.text-holder" components={[<strong />]} /></h3>
                </IonListHeader>
                <IonItem>
                    <IonLabel position="floating" className="input-form">{t('RecoveryPassword.input-dnicifnie')}
                    </IonLabel>
                    <IonInput name="dnicifnie" type="text" value={dnicifnie}
                              onIonChange={e => setDnicifnie(e.detail.value.toUpperCase())}>
                    </IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position="floating" className="input-form">{t('RecoveryPassword.input-phoneNumber')}</IonLabel>
                    <IonInput name="telephone" type="number" value={telephone}
                              onIonChange={e => setTelephone(e.detail.value)}>
                    </IonInput>
                </IonItem>
            </IonList></>
    )
}

const RecoveryPasswordUserLine = (t, telephone, setTelephone) => {

    return (<>
            <IonList>
                <IonListHeader lines="inset" className="title-subheader">
                    <h3 className="list-header"><Trans i18nKey="RecoveryPassword.text-user" components={[<strong />]} /></h3>
                </IonListHeader>
                <IonItem>
                    <IonLabel position="floating" className="input-form">{t('RecoveryPassword.input-phoneNumber')}</IonLabel>
                    <IonInput name="telephone" type="number" value={telephone}
                              onIonChange={e => setTelephone(e.detail.value)}>
                    </IonInput>
                </IonItem>
            </IonList></>
    )
}


export default RecoveryPassword