import {
    IonCol,
    IonIcon,
    IonItem,
    IonLabel
} from "@ionic/react";

import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {settingsSharp} from "ionicons/icons";

export default function ListLinesBonusShared({element, setOldLineClick, setShowAlert}) {

    const functionClickLine = async (e) => {
        let auxDataLineClick = e.currentTarget.value
        let auxData = {
            "phoneNumber": auxDataLineClick.substring(0, auxDataLineClick.indexOf('#')),
            "percentConsumptionOnSharedBonus": auxDataLineClick.substring(auxDataLineClick.indexOf('#')+1)
        }
        setOldLineClick(auxData)
        setShowAlert(true)
    }

    return (
        <>
            <IonItem value={element.phoneNumber + '#' + element.percentConsumptionOnSharedBonus} button={true} onClick={(e) => { functionClickLine(e) }}>
                <IonCol className="one-col">
                    <IonLabel>
                        <CircularProgressbar
                            value={element.percentConsumptionOnSharedBonus}
                            text={
                                <tspan>
                                    <tspan x="50" y="50">{element.percentConsumptionOnSharedBonus} %</tspan>
                                </tspan>
                            }
                            strokeWidth={6}
                            counterClockwise={true}
                            styles={buildStyles({
                                textSize: "25px", pathColor: "#008AC7", trailColor: "#C5E0EA", textColor: "#00467B"
                            })}
                        />
                    </IonLabel>
                </IonCol>
                <IonCol size="6" className="two-col">
                    <h2>{element.phoneNumber}</h2>
                </IonCol>
                <IonCol size="2" className="three-col">
                    <h1><IonIcon md={settingsSharp}/></h1>
                </IonCol>
            </IonItem>

        </>
    )
}