import axios from "axios";

const get = async <T>(url: string, body: any) => {
    return await axios.get(url, body).catch(error => {
        return error.response
    }) as T
}

const post = async <T>(url: string, body: any) => {
    return await axios.post(url, body).catch(error => {
        return error.response
    }) as T
}

const patch = async <T>(url: string, body: any, header: any) => {
    return await axios.patch(url, body, header).catch(error => {
        return error.response
    }) as T
}


export const axiosHttp = {
    get,
    post,
    patch
}