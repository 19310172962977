import {
    apiMobileIpv6InformaticaRepository
} from "../../../Infrastructure/Repositories/ApiMobileIpv6Informatica/ApiMobileIpv6Informatica.repository";
import {NumberNotFound} from "./NumberNotFound.exception";
import {IdentificationNotFound} from "./IdentificationNotFound.exception";
import {EmailNotFound} from "./EmailNotFound.exception";
import {NumberEmailNotFound} from "./NumberEmailNotFound.exception";
import i18n from '../../../Infrastructure/Translations/I18next/i18next.config';

const recoveryPassword = async (body) => {

    let {data, status} = await apiMobileIpv6InformaticaRepository.recoveryPasswordRequest(body)

    if (status >= 400 && data.message.includes('El número de teléfono introducido no existe')) throw new NumberNotFound(status, i18n.t('RecoveryPassword.text-forgot-numbernotfound'))
    if (status >= 400 && data.message.includes('El número NIF o CIF o NIE introducido no existe')) throw new IdentificationNotFound(status, i18n.t('RecoveryPassword.text-forgot-identificationnotfound'))
    if (status >= 400 && data.message.includes('No existe ningún email registrado con el NIF o CIF o NIE introducido')) throw new EmailNotFound(status, i18n.t('RecoveryPassword.text-forgot-emailnotfound'))
    if (status >= 400 && data.message.includes('No existe ningún email registrado para el número de teléfono introducido')) throw new NumberEmailNotFound(status, i18n.t('RecoveryPassword.text-forgot-numberemailnotfound'))

    return i18n.t('RecoveryPassword.text-forgot-confirmation', { email: data.message.email })
}

export const RecoveryPasswordService = {
    recoveryPassword
}