import {
    IonAlert,
    IonButtons,
    IonContent,
    IonHeader, IonList, IonListHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import {useEffect, useState} from "react";
import './SharedBonus.css'
import Loading from "../../SharedComponents/Loading/Loading";
import ListLinesBonusShared from "./ListLinesBonusShared/ListLinesBonusShared";
import {obtainSharedBonusLine} from "../../../../Application/BonusShared/ObtainSharedBonusLine";
import {changeSharedBonusLine} from "../../../../Application/BonusShared/ChangeSharedBonusLine";
import {
    ErrorModifyConsumptionPercentage
} from "../../../../Domain/Services/BonusShared/ErrorModifyConsumptionPercentage.exception";
import {useTranslation} from "react-i18next";
import {obtainSummaryConsumptions} from "../../../../Application/Lines/ObtainSummaryConsumptions/ObtainConsumptions";

const SharedBonus = ({sessionUser, sessionUserLinesData}) => {
    const {t} = useTranslation();
    const linesSharedBonus = []
    let totalMbConsumed = 0
    const [dataOldLineClick, setOldLineClick] = useState([])
    const [dataNewLineClick, setNewLineClick] = useState([])
    const [showAlert, setShowAlert] = useState(false)
    const [dataTotalMbConsumed, setDataTotalMbConsumed] = useState(0)
    const [dataAllLinesBonusShared, setDataAllLinesBonusShared] = useState([])
    const [showAlert2, setShowAlert2] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    let linesWithSharedBonus = sessionUserLinesData.filter(e => e.rate.isShared === "1");

    useEffect(function () {

        const fetchData = async () => {
            setIsLoading(true)
            if (dataNewLineClick.length !== 0) {
                try {
                    await changeSharedBonusLine(dataNewLineClick.id, dataNewLineClick, sessionUser.userLoggedIn.accessToken)
                } catch (e) {
                    if (e instanceof ErrorModifyConsumptionPercentage) setShowAlert2(e.messageError)
                    setIsLoading(false)
                }
            }

            for (const line of linesWithSharedBonus) {
                let dataLineSharedBonus = await obtainSharedBonusLine(line.numberLine, sessionUser.userLoggedIn.accessToken)
                linesSharedBonus.push(dataLineSharedBonus)

                let summaryConsumptionLine = await obtainSummaryConsumptions(line.numberLine, line.rate.provider, sessionUser.userLoggedIn.accessToken)
                totalMbConsumed += summaryConsumptionLine.data;
            }

            setDataTotalMbConsumed(totalMbConsumed);
            setDataAllLinesBonusShared(linesSharedBonus)

            setIsLoading(false)
        }

        fetchData().then()

    }, [dataNewLineClick])

    const updatePercentageLine = (dataForm, numberLineUpdate) => {
        let lineFound = sessionUserLinesData.find(element => element.numberLine === numberLineUpdate);

        let auxDataNew = {
            "id": numberLineUpdate,
            "providerId": lineFound.rate.provider,
            "consumptionPercentage": dataForm,
        }

        setNewLineClick(auxDataNew)
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="toolbar">
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>{t('SharedBonus.page-title')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonList lines="inset">
                    <IonListHeader lines="inset" className="header-list">
                        {
                            dataAllLinesBonusShared.length === 0 ?
                                <h6 className="list-header">{t('SharedBonus.text-subtitle-notsharedbonus')}</h6> :
                                <h6 className="list-header">{t('SharedBonus.text-subtitle')}
                                    <strong> {dataAllLinesBonusShared[0].totalGb} GB.</strong></h6>
                        }

                    </IonListHeader>
                    {


                        dataAllLinesBonusShared.length === 0 ?
                            "" :
                            <IonListHeader lines="inset" className="header-list"><h6
                                className="list-header">{t('SharedBonus.text-subtitle-consumed')}
                                <strong> {(dataTotalMbConsumed / Math.pow(1024, 1)).toFixed(2)} GB.</strong></h6>
                            </IonListHeader>
                    }
                    {
                        dataAllLinesBonusShared.map((element, index) => {
                            return (<ListLinesBonusShared key={index} element={element} setShowAlert={setShowAlert}
                                                          setOldLineClick={setOldLineClick}/>)
                        })
                    }
                </IonList>
            </IonContent>
            <Loading isLoading={isLoading}/>
            <IonAlert
                isOpen={showAlert}
                header={t('SharedBonus.text-title-alert') + dataOldLineClick.phoneNumber}
                buttons={[
                    t('SharedBonus.button-cancel-alert'),
                    {
                        text: t('SharedBonus.button-change-alert'),
                        handler: (dataForm) => {
                            updatePercentageLine(dataForm, dataOldLineClick.phoneNumber)
                        }
                    }
                ]}
                inputs={[
                    {
                        type: 'radio',
                        label: '100 %',
                        value: '100',
                        checked: dataOldLineClick.percentConsumptionOnSharedBonus === '100'
                    },
                    {
                        type: 'radio',
                        label: '75 %',
                        value: '75',
                        checked: dataOldLineClick.percentConsumptionOnSharedBonus === '75'
                    },
                    {
                        type: 'radio',
                        label: '50 %',
                        value: '50',
                        checked: dataOldLineClick.percentConsumptionOnSharedBonus === '50'
                    },
                    {
                        type: 'radio',
                        label: '25 %',
                        value: '25',
                        checked: dataOldLineClick.percentConsumptionOnSharedBonus === '25'
                    },
                    {
                        type: 'radio',
                        label: '10 %',
                        value: '10',
                        checked: dataOldLineClick.percentConsumptionOnSharedBonus === '10'
                    },
                ]}
                onDidDismiss={() => setShowAlert(false)}
            />
            <IonAlert
                isOpen={showAlert2 !== false}
                onDidDismiss={() => setShowAlert2(false)}
                cssClass='my-custom-class'
                header={t('SharedBonus.text-title-alert-error')}
                message={showAlert2}
                buttons={[t('SharedBonus.button-accept-alert-error')]}
            />
        </IonPage>
    );
};

export default SharedBonus;
