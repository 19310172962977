import {axiosHttp} from '../../HttpClient/Axios/Axios.http'

const URLBase = process.env.REACT_APP_APIMOBILE

const getCustomerPersonalData = async (sessionUsername: string, sessionAccesstoken: string): Promise<any> => {
    return await axiosHttp.get<any>(URLBase + "/customers", {
        params: {'id': sessionUsername},
        headers: {'Authorization': `Bearer ${sessionAccesstoken}`}
    })
}

const getCustomerAllLines = async (sessionUsername: string, sessionAccesstoken: string): Promise<any> => {
    return await axiosHttp.get<any>(URLBase + "/customers/" + sessionUsername + "/lines", {headers: {'Authorization': `Bearer ${sessionAccesstoken}`}})
}

const getUserLineData = async (sessionUsername: string, sessionAccesstoken: string): Promise<any> => {
    return await axiosHttp.get<any>(URLBase + "/lines/" + sessionUsername, {headers: {'Authorization': `Bearer ${sessionAccesstoken}`}})
}

const getSummaryLineConsumption = async (selectedLine: string, lineProvider: string, sessionAccesstoken: string): Promise<any> => {
    return await axiosHttp.get<any>(URLBase + "/lines/" + selectedLine + "/consumptions", {
        params: {'providerId': lineProvider},
        headers: {'Authorization': `Bearer ${sessionAccesstoken}`}
    })
}

const getConsumptionsHistory = async (requestBody: object, selectedLine: string, sessionAccesstoken: string) => {
    return await axiosHttp.get<any>(URLBase + "/lines/" + selectedLine + "/consumptions-history", {
        params: requestBody,
        headers: {'Authorization': `Bearer ${sessionAccesstoken}`}
    });
}

const getInvoices = async (sessionUsername: string, sessionAccesstoken: string) => {
    return await axiosHttp.get<any>(URLBase + "/customers/" + sessionUsername + "/invoices", {headers: {'Authorization': `Bearer ${sessionAccesstoken}`}})
}

const getLineSharedBonus = async (phoneNumber: string, sessionAccesstoken: string) => {
    return await axiosHttp.get<any>(URLBase + "/lines/" + phoneNumber + "/shared-bonus", {headers: {'Authorization': `Bearer ${sessionAccesstoken}`}})
}

const changeConsumptionPercentage = async (phoneNumber: string, body: any, sessionAccesstoken: string) => {
    return await axiosHttp.patch<any>(URLBase + "/lines/" + phoneNumber + "/consumption-percentage", body, {headers: {'Authorization': `Bearer ${sessionAccesstoken}` }})
}

const recoveryPasswordRequest = async (body: any) => {
    return await axiosHttp.post<any>(URLBase + "/recovery-password-request", body)
}

const registerUser = async (body: any) => {
    return await axiosHttp.post<any>(URLBase + "/registrations", body)
}

const login = async (username: string, password: string): Promise<any> => {
    return await axiosHttp.post<any>(URLBase + "/login", {username: username, password: password})
}

const getLinesServices = async (phoneNumber, provider, sessionAccesstoken) => {
    return await axiosHttp.get<any>(URLBase + "/lines/" + phoneNumber + "/services", { params: { 'providerId': provider }, headers: {'Authorization': `Bearer ${sessionAccesstoken}` }})
}

const changeLinesService = async (phoneNumber, body, sessionAccesstoken) => {
    return await axiosHttp.patch<any>(URLBase + "/lines/" + phoneNumber + "/services", body, {headers: {'Authorization': `Bearer ${sessionAccesstoken}` }})
}

export const apiMobileIpv6InformaticaRepository = {
    getCustomerPersonalData,
    getCustomerAllLines,
    getUserLineData,
    getSummaryLineConsumption,
    getConsumptionsHistory,
    getInvoices,
    getLineSharedBonus,
    changeConsumptionPercentage,
    recoveryPasswordRequest,
    registerUser,
    getLinesServices,
    changeLinesService,
    login
}