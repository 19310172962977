import {getConsumptionHistoryByDate} from "../../../Domain/Services/Lines/Lines.service";
import {Utils} from "../../../Domain/Utils/Utils";

export const obtainConsumptionHistory = async (typeConsumption: string, selectedLine: string, sessionAccesstoken: string) => {
    let requestBody = {
        "typeConsumption": typeConsumption,
        "from": Utils.getStartDateCurrentMonth(),
        "to": Utils.getEndDateCurrentMonth()
    }
    return await getConsumptionHistoryByDate(requestBody, selectedLine, sessionAccesstoken)
}