import {
    apiMobileIpv6InformaticaRepository
} from "../../../Infrastructure/Repositories/ApiMobileIpv6Informatica/ApiMobileIpv6Informatica.repository";
import {Utils} from "../../Utils/Utils";
import {TLine} from "../../Models/Line/Line.model";
import {TDataBonus} from "../../Models/DataBonus/DataBonus.model";
import {TRate} from "../../Models/Rate/Rate.model";
import {TUser} from "../../Models/User/User.model";
import {TUserLine} from "../../Models/UserLine/UserLine.model";
import {TVoiceBonus} from "../../Models/VoiceBonus/VoiceBonus.model";
import {TAdditionalBonus} from "../../Models/AdditionalBonus/AdditionalBonus.model";

const getDataUserLine = async (userLogged: TUser) => {

    const {data} = await apiMobileIpv6InformaticaRepository.getUserLineData(userLogged.userName, userLogged.accessToken)
    let objLine: TLine
    let objRate: TRate
    let objDataBonus: TDataBonus | null = null
    let objVoiceBonus: TVoiceBonus | null = null
    let objAdditionalBonuses: TAdditionalBonus | null = null

    if (data.dataBonus !== null) {
        objDataBonus = {
            id: data.dataBonus.id,
            name: data.dataBonus.name,
            megas: data.dataBonus.megas,
            minutes: data.dataBonus.minutes,
            price: Utils.calculatePriceIncludingIVA(data.dataBonus.price),
            provider: data.dataBonus.providerId,
            subscriptionDatetime: null,
            unSubscriptionDatetime: null
        }
    }

    let currentDate = new Date(new Date().setHours(0,0,0,0))
    let additionalBonusesActive = data.additionalBonuses.filter(element => element.unSubscriptionDatetime === null || Utils.formatStringToDate(element.unSubscriptionDatetime) >= currentDate);
    objAdditionalBonuses = getActiveAdditionalBonuses(additionalBonusesActive)

    objRate = {
        id: data.rate.id,
        price: Utils.calculatePriceIncludingIVA(data.rate.price),
        megas: data.rate.megas,
        minutes: data.rate.minutes,
        name: data.rate.name,
        provider: data.rate.providerId,
        coverage: data.rate.coverage,
        isShared: data.rate.isShared
    }
    objLine = {
        numberLine: data.id,
        customerLine: data.customerId,
        sharedBonusPercentage: data.sharedBonusPercentage,
        dataBonus: objDataBonus,
        additionalBonuses: objAdditionalBonuses,
        voiceBonus: objVoiceBonus,
        rate: objRate
    }

    return [objLine]

}

const getActiveAdditionalBonuses = (additionalBonusesActive): TAdditionalBonus => {

    let sumMinutes = 0;
    let sumMegabytes = 0;
    let collectionAdditionalBonuses: TVoiceBonus[] | TDataBonus[] = additionalBonusesActive.map(element => {
        let objBonus: TDataBonus | TVoiceBonus = {
            id: element.bonus.id,
            price: Utils.calculatePriceIncludingIVA(element.bonus.price),
            name: element.bonus.name,
            megas: String(element.bonus.megas).length > 0 ? element.bonus.megas : null,
            minutes: String(element.bonus.minutes).length > 0 ? element.bonus.minutes : null,
            provider: element.bonus.providerId,
            subscriptionDatetime: element.bonus.subscriptionDatetime,
            unSubscriptionDatetime: element.bonus.unSubscriptionDatetime
        }

        if (String(objBonus.megas).length > 0) sumMegabytes = sumMegabytes + Number(objBonus.megas)
        if (String(objBonus.minutes).length > 0) sumMinutes = sumMinutes + Number(objBonus.minutes)

        return objBonus
    })

    return {
        bonus: collectionAdditionalBonuses,
        totalmegabytes: sumMegabytes,
        totalminutes: sumMinutes
    };
}

const getPersonalInformation = async (userLogged: TUser) => {

    const objUserLine: TUserLine = {
        name: userLogged.userName,
        userLoggedIn: userLogged
    }

    return objUserLine
}


export const UserLineService = {
    getDataUserLine,
    getPersonalInformation
}
