import {IonItem, IonLabel,IonToggle} from "@ionic/react";
import 'react-circular-progressbar/dist/styles.css';
import {useTranslation} from "react-i18next";

function titleToggle(t, element){

    if(element.name === "roaming") return t('ServicesLines.text-option-roaming')
    if(element.name === "voicemail") return t('ServicesLines.text-option-voicemail')
    if(element.name === "forwarding") return t('ServicesLines.text-option-callforwarding') + (element.active ? (t('ServicesLines.text-option-callforwarding-article') + element.number) : '')
}

export default function ListServicesLines({element, setServiceClick, setServiceForwardingClick}) {
    const { t } = useTranslation();
    const clickToggle = async (e) => {
        let auxDataToggle

        if(element.name !== 'forwarding'){
            auxDataToggle = {
                "name": element.name,
                "active": !element.active
            }
            setServiceClick(auxDataToggle)
        }

        if(element.name === 'forwarding'){
            auxDataToggle = {
                "name": element.name,
                "active": !element.active,
                "number": element.number
            }
            setServiceForwardingClick(auxDataToggle)
        }

    }

    return (
        <>
            <IonItem value={element.name} button={true} onClick={(e) => { clickToggle(e) }}>
                <IonLabel>{titleToggle(t, element)}</IonLabel>
                <IonToggle slot="end" color="success" checked={element.active}/>
            </IonItem>

        </>
    )
}