import {
    IonAlert, IonBackButton,
    IonButton, IonButtons, IonCardContent, IonCardHeader, IonCardTitle,
    IonCol, IonContent, IonHeader, IonIcon,
    IonInput, IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow, IonToolbar,
} from "@ionic/react";
import React, {useState} from "react";
import Loading from "../../SharedComponents/Loading/Loading";
import './SignUp.css';
import { v4 as uuidv4 } from 'uuid';
import MessageTextError from "../../SharedComponents/MessageTextError/MessageTextError";
import {eyeOffOutline, eyeOutline} from "ionicons/icons";
import {startRegistration} from "../../../../Application/Registration/StartRegistration";
import {NumberNotFound} from "../../../../Domain/Services/Registration/NumberNotFound.exception";
import {NumberAlreadyRegistration} from "../../../../Domain/Services/Registration/NumberAlreadyRegistration.exception";
import type {TextFieldTypes} from '@ionic/core/dist/types/interface';
import {useTranslation} from "react-i18next";

const SignUp = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [telephone, setTelephone] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [showAlert, setShowAlert] = useState<string|boolean>(false);
    const [showError, setShowError] = useState(false);
    const [typeInputPassword, setTypeInputPassword] = useState<TextFieldTypes>('password');
    const [iconEye, setIconEye] = useState(eyeOutline);


    const togglePasswordMode = () => {
        if(typeInputPassword === 'password'){
            setTypeInputPassword('text')
            setIconEye(eyeOffOutline)
        }else{
            setTypeInputPassword('password')
            setIconEye(eyeOutline)
        }

    }

    const handleRegister = async (e) => {
        e.preventDefault();

        if (!telephone || !email || !password ) setShowError(true)

        if (telephone && email && password ){
            setIsLoading(true)
            let textRequest = {
                "id": uuidv4(),
                "phoneNumber": telephone,
                "email": email,
                "password": password
            }

            try{
                const response = await startRegistration(textRequest)
                setShowAlert(response)
            }catch(e){
                if (e instanceof NumberNotFound||NumberAlreadyRegistration) setShowAlert(e.messageError)
            }

            setIsLoading(false)
            setShowAlert(true)
        }
    };

    return (
        <IonPage id="login-page">
            <IonContent fullscreen>
                <IonHeader>
                    <IonToolbar>
                        <div className="title-card"><IonCardTitle>{t('Signin.page-title')}</IonCardTitle></div>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="home" />
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonCardContent>
                    <form noValidate onSubmit={handleRegister}>
                        <IonList>
                            <IonItem>
                                <IonLabel position="floating" className="input-form">{t('Signin.input-phoneNumber')}</IonLabel>
                                <IonInput name="phonenumber" type="number" value={telephone}
                                          onIonChange={e => setTelephone(e.detail.value)}>
                                </IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating" className="input-form">{t('Signin.input-email')}</IonLabel>
                                <IonInput name="email" type="email" value={email}
                                          onIonChange={e => setEmail(e.detail.value)}>
                                </IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="floating" className="input-form">{t('Signin.input-password')}</IonLabel>
                                <IonInput name="password" type={typeInputPassword} value={password}
                                          onIonChange={e => setPassword(e.detail.value)}>
                                </IonInput>
                                <IonButton  fill="clear" type="button" slot="end"  className="btn_eye_icon" onClick={() =>togglePasswordMode()}  >
                                    <IonIcon  className="icon_eye" icon={iconEye}></IonIcon>
                                </IonButton>
                            </IonItem>
                        </IonList>
                        <IonRow>
                            <IonCol>
                                <IonButton type="submit" expand="block" className="botton-actions">{t('Signin.button-signin')}</IonButton>
                                {showError ? <MessageTextError textMessage={t('Signin.text-error-inputsempty')}/> : ''}
                            </IonCol>
                        </IonRow>
                    </form>
                </IonCardContent>
            </IonContent>
            <Loading isLoading={isLoading}/>
            <IonAlert
                isOpen={showAlert !== false}
                onDidDismiss={() => setShowAlert(false)}
                cssClass='my-custom-class'
                header={t('Signin.text-title-alert')}
                message={(showAlert as string)}
                buttons={[t('Signin.button-alert')]}
            />
        </IonPage>

    );
}

export default SignUp