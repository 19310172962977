import {CustomError} from 'ts-custom-error'

export class NumberNotFound extends CustomError {
    public constructor(
        public codeError: number,
        public messageError: string,
        message?: string,
    ) {
        super(message)
    }
}