import {
    IonButtons,
    IonCardContent,
    IonContent,
    IonHeader, IonList,
    IonMenuButton,
    IonPage,
    IonToolbar
} from '@ionic/react';
import SummarySelectedLine from "./SummarySelectedLine/SummarySelectedLine"
import ListOfLines from "../../SharedComponents/ListOfLines/ListOfLines";
import {FC, useState} from "react";
import './Home.css'
import {TUserOwner} from "../../../../Domain/Models/UserOwner/UserOwner.model";
import {TUserLine} from "../../../../Domain/Models/UserLine/UserLine.model";
import {TLine} from "../../../../Domain/Models/Line/Line.model";

interface HomeProps {
    sessionUser: TUserLine|TUserOwner
    sessionUserLinesData: TLine
}

const Home: FC<HomeProps>= ({sessionUser, sessionUserLinesData}) => {

    const [selectedLine, setSelectedLine] = useState(sessionUserLinesData[0].numberLine)

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="toolbar">
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <ListOfLines
                        dataList={sessionUserLinesData}
                        valueSelected={selectedLine} onListChange={setSelectedLine}/>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <div className="login-logo">
                    <img src="assets/img/logo_app_2022_544px.png"/>
                </div>
                <IonCardContent>
                    <IonList lines="none">
                        <SummarySelectedLine line={selectedLine} data={sessionUserLinesData} sessionUser={sessionUser}/>
                    </IonList>
                </IonCardContent>
            </IonContent>
        </IonPage>
    );
};

export default Home;
