import {
    IonButton, IonCardContent, IonCheckbox,
    IonCol, IonContent, IonIcon,
    IonInput, IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonRow
} from "@ionic/react";
import {useEffect, useState} from "react";
import './Login.css'
import MessageTextError from "../SharedComponents/MessageTextError/MessageTextError";
import Loading from "../SharedComponents/Loading/Loading";
import {eyeOutline, eyeOffOutline} from "ionicons/icons";
import {Storage} from '@capacitor/storage'
import { useTranslation } from 'react-i18next';
import {InvalidUserCredentials} from "../../../Domain/Services/User/InvalidUserCredentials.exception";
import {startUserSession} from "../../../Application/Auth/StartUserSession";
import {obtainPersonalDataUserOwner} from "../../../Application/UserOwner/ObtainPersonalDataUserOwner";
import {obtainAllLinesUserOwner} from "../../../Application/UserOwner/ObtainAllLinesUserOwner";
import {obtainPersonalDataUserLine} from "../../../Application/UserLine/ObtainPersonalDataUserLine";
import {obtainDataUserLine} from "../../../Application/UserLine/ObtainDataUserLine";
import type {TextFieldTypes} from '@ionic/core/dist/types/interface';

const Login = ({setIsAuthenticated}) => {

    const { t } = useTranslation();

    const [username, setUsername] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [password, setPassword] = useState('');
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [typeInputPassword, setTypeInputPassword] = useState<TextFieldTypes>("password");
    const [iconEye, setIconEye] = useState(eyeOutline);
    const [checkRemember, setCheckRemember] = useState(false);

    useEffect(() => {
        Storage.get({key: 'name'}).then(e => {
            if (e.value !== null) setUsername(e.value);
        });
        Storage.get({key: 'pass'}).then(e => {
            if (e.value !== null) setPassword(e.value);
        });
    }, []);

    const checkRememberUsernameAndPassword = (username, password) => {
        Storage.set({key: 'name', value: username});
        Storage.set({key: 'pass', value: password});
    }

    const hideOrShowPassword = () => {
        if (typeInputPassword === 'password') {
            setTypeInputPassword("text")
            setIconEye(eyeOffOutline)
        } else {
            setTypeInputPassword("password")
            setIconEye(eyeOutline)
        }

    }
    const handleLogin = async (e) => {
        e.preventDefault();

        setUsernameError(false)
        setPasswordError(false)
        setLoginError(false)

        if (!username) {
            setUsernameError(true);
        }
        if (!password) {
            setPasswordError(true);
        }

        if (username && password) {
            try {
                setIsLoading(true)
                const userLogged = await startUserSession(username, password)

                if(userLogged.isOwner){
                    await obtainPersonalDataUserOwner(userLogged)
                    await obtainAllLinesUserOwner(userLogged)
                }else{
                    await obtainPersonalDataUserLine(userLogged)
                    await obtainDataUserLine(userLogged)
                }

                setIsLoading(false)
                setIsAuthenticated(true)
            } catch (e) {
                if (e instanceof InvalidUserCredentials) setLoginError(true)
                setIsLoading(false)
            }

            if (checkRemember) checkRememberUsernameAndPassword(username, password);
        }
    };

    return (
        <IonPage id="login-page">
            <IonContent>
                <div className="login-logo">
                    <img src="assets/img/logo_app_2022_544px.png"/>
                </div>
                <IonCardContent>
                    <form noValidate onSubmit={handleLogin}>
                        <IonList>
                            <IonItem>
                                <IonLabel position="floating" className="input-form">{t('Login.input-username')}</IonLabel>
                                <IonInput name="username" type="text" value={username} spellCheck={false}
                                          autocapitalize="off"
                                          onIonChange={e => setUsername(e.detail.value.toUpperCase())}
                                          required>
                                </IonInput>
                            </IonItem>

                            {usernameError ? <MessageTextError textMessage={t('Login.text-error-username')}/> : ''}

                            <IonItem>
                                <IonLabel position="floating" className="input-form">{t('Login.input-password')}</IonLabel>
                                <IonInput name="password" type={typeInputPassword} value={password}
                                          onIonChange={e => setPassword(e.detail.value)}>
                                </IonInput>
                                <IonButton fill="clear" type="button" slot="end" className="btn_eye_icon"
                                           onClick={() => hideOrShowPassword()}>
                                    <IonIcon className="icon_eye" icon={iconEye}></IonIcon>
                                </IonButton>
                            </IonItem>

                            {passwordError ? <MessageTextError textMessage={t('Login.text-error-password')}/> : ''}

                            <IonItem lines="none" className="checkbox-remember-passsword">
                                <IonLabel>{t('Login.check-remember-credentials')}</IonLabel>
                                <IonCheckbox className="checkbox-element" slot="start" checked={checkRemember}
                                             onIonChange={e => setCheckRemember(e.detail.checked)}/>
                            </IonItem>

                        </IonList>
                        <IonRow>
                            <IonCol>
                                <IonButton type="submit" expand="block"
                                           className="botton-actions">{t('Login.button-login')}</IonButton>

                                {loginError ? <MessageTextError
                                    textMessage={t('Login.text-error-credentials')}/> : ''}
                            </IonCol>

                        </IonRow>

                    </form>
                    <IonRow>
                        <IonCol>
                            <IonButton routerLink="/sign-up" expand="block"
                                       className="botton-subactions">{t('Login.button-signin')}</IonButton>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonButton routerLink="/recovery-password" expand="block" className="botton-subactions">{t('Login.button-forgot-password')}</IonButton>
                        </IonCol>
                    </IonRow>
                </IonCardContent>
            </IonContent>
            <Loading isLoading={isLoading}/>
        </IonPage>
    );
}

export default Login