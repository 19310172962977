import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
} from '@ionic/react';
import {
    documentTextOutline,
    peopleCircleOutline,
    personOutline, powerOutline, settingsOutline
} from 'ionicons/icons';
import {useLocation} from 'react-router-dom';
import './Menu.css';
import {useTranslation} from "react-i18next";

const pagesHome = [
    {
        title: 'Menu.page-title-invoices',
        url: '/dashboard/invoices',
        icon: documentTextOutline,
        isOwner: true
    },
    {
        title: 'Menu.page-title-sharedplan',
        url: '/dashboard/shared-bonus',
        icon: peopleCircleOutline,
        isOwner: true
    },
    {
        title: 'Menu.page-title-serviceslines',
        url: '/dashboard/services-lines',
        icon: settingsOutline,
        isOwner: true
    }
]

const pagesProfile = [
    {
        title: 'Menu.page-title-personalinfo',
        url: '/dashboard/account',
        icon: personOutline,
        isOwner: true
    },
    {
        title: 'Menu.page-title-logoff',
        url: '/logout',
        icon: powerOutline
    }
]

const Menu = ({sessionUser}) => {
    const { t } = useTranslation();
    const locationCurrent = useLocation();

    return (
        <IonMenu contentId="main" type="overlay" >
            <IonContent >
                <IonList id="inbox-list">
                    <IonListHeader className="title-menu">{sessionUser.userLoggedIn.isOwner ? sessionUser.name + " " + sessionUser.surName1 + " "+ sessionUser.surName2 : sessionUser.name }</IonListHeader>
                </IonList>
                <IonList id="label-list">
                    {pagesHome.map((appPage, index) => {
                        if (appPage.isOwner === sessionUser.userLoggedIn.isOwner) {
                            return (
                                <IonMenuToggle key={index} autoHide={false}>
                                    <IonItem className={locationCurrent.pathname === appPage.url ? 'selected' : ''}
                                             routerLink={appPage.url} routerDirection="none" lines="none"
                                             detail={false}>
                                        <IonIcon slot="start" md={appPage.icon}/>
                                        <IonLabel>{t(appPage.title)}</IonLabel>
                                    </IonItem>
                                </IonMenuToggle>
                            );
                        }
                    })}
                </IonList>
                <IonList id="labels-list">
                    <IonListHeader>{t('Menu.page-subtitle-profile')}</IonListHeader>
                    {pagesProfile.map((onePage, index) => {
                        if (onePage.isOwner === sessionUser.userLoggedIn.isOwner || onePage.isOwner === undefined) {
                            return (
                                <IonMenuToggle key={index} autoHide={false}>
                                    <IonItem className={locationCurrent.pathname === onePage.url ? 'selected' : ''}
                                             routerLink={onePage.url} routerDirection="none" lines="none"
                                             detail={false}>
                                        <IonIcon slot="start" md={onePage.icon}/>
                                        <IonLabel>{t(onePage.title)}</IonLabel>
                                    </IonItem>
                                </IonMenuToggle>
                            );
                        }
                    })}
                </IonList>
            </IonContent>
        </IonMenu>
    );
};

export default Menu;
