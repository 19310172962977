import {
    apiMobileIpv6InformaticaRepository
} from "../../../Infrastructure/Repositories/ApiMobileIpv6Informatica/ApiMobileIpv6Informatica.repository";
import {NumberNotFound} from "./NumberNotFound.exception";
import {NumberAlreadyRegistration} from "./NumberAlreadyRegistration.exception";
import i18n from '../../../Infrastructure/Translations/I18next/i18next.config';

const requestRegistration = async (body: any) => {
    let {data, status} = await apiMobileIpv6InformaticaRepository.registerUser(body)

    if (status >= 400 && data.message.includes('El número de teléfono introducido no existe')) throw new NumberNotFound(status, i18n.t('Signin.text-signin-numbernotfound'))
    if (status >= 400 && data.message.includes('El número de teléfono introducido ya esta registrado')) throw new NumberAlreadyRegistration(status, i18n.t('Signin.text-signin-numberalreadyregistration'))

    return i18n.t('Signin.text-signin-confirmation', { phonenumber: body.phoneNumber })
}

export const RegistrationService = {
    requestRegistration
}