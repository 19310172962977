import {IonText} from "@ionic/react";

export default function MessageTextError({textMessage}) {
    return (
        <>
            <IonText color="danger">
                <p className="ion-padding-start">
                    {textMessage}
                </p>
            </IonText>
        </>
    )
}