import {IonLoading} from "@ionic/react";
import {useTranslation} from "react-i18next";

export default function Loading({isLoading}) {
    const { t } = useTranslation();
    return (<IonLoading
        isOpen={isLoading}
        message={t('Loading.text')}
        spinner={'circular'}
    />)
}
