import {IonCardContent, IonItem, IonLabel, IonList, IonListHeader, IonNote,} from "@ionic/react";
import {Bar} from "react-chartjs-2";
import dataGraph, {
    dataGraphBar,
    optionGraphBar
} from "../../../../ProgressBar/CircularProgressBar/CircularProgressBar.config";
import './SegmentList.css'
import {useTranslation} from "react-i18next";

function convertToGigabytesOrMinutes(data, typeConsumption, withDataType = false) {
    let dataConverted;
    if(typeConsumption === "Voice"){
        dataConverted = (data / 60).toFixed(2)
        if (withDataType) dataConverted = printSecondsMinutesAndHours(data);

    }else if (typeConsumption === "Data"){
        dataConverted = (data / Math.pow(1024, 1)).toFixed(3)
        if (withDataType) dataConverted = dataConverted + " GB."
    }else{
        dataConverted = data
        if (withDataType) dataConverted = dataConverted + " SMS"
    }
    return dataConverted
}

function printSecondsMinutesAndHours(data) {
    let hour = Math.floor(data / 3600);
    hour = (hour < 10)? '0' + hour : hour;
    let minute = Math.floor((data / 60) % 60);
    minute = (minute < 10)? '0' + minute : minute;
    let second = data % 60;
    second = (second < 10)? '0' + second : second;
    return hour + 'h ' + minute + 'm ' + second + 's';
}

export default function SegmentList({dataConsumptions, show, titleList}) {

    const { t } = useTranslation();
    let auxConsumptions = []
    let auxDays = []

    dataConsumptions.map(element => {
        auxConsumptions.push(convertToGigabytesOrMinutes(element.totalConsumption, element.typeConsumption))
        auxDays.push(element.date.substr(-2))
    })

    return (
        <IonList lines="full" style={!show ? {display: 'none'} : {}}>
            <IonCardContent>
                <Bar data={dataGraphBar(auxDays, auxConsumptions)} options={optionGraphBar()}/>
            </IonCardContent>
            <IonListHeader lines="none" className="title-list">{titleList}</IonListHeader>
            {
                dataConsumptions.map((element, index) => {
                    return (<IonItem key={index} className="item-list">
                        <IonLabel>{t('Consumptions.text-day')} {element.date.substr(-2)}</IonLabel>
                        <IonNote slot="end" color="dark">{convertToGigabytesOrMinutes(element.totalConsumption, element.typeConsumption, true)}</IonNote>
                    </IonItem>)
                })
            }
        </IonList>

    )
}
