import {
    IonButtons,
    IonContent, IonHeader,
    IonMenuButton,
    IonPage, IonSegment, IonSegmentButton,
    IonToolbar
} from '@ionic/react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ListOfLines from "../../SharedComponents/ListOfLines/ListOfLines";
import {useState, useEffect, FC} from "react";
import SegmentList from "./SegmentList/SegmentList";
import Loading from "../../SharedComponents/Loading/Loading";
import './Consumptions.css';
import {
    obtainConsumptionHistory
} from "../../../../Application/Lines/ObtainConsumptionsHistory/ObtainConsumptionHistoryDate";
import {TUserLine} from "../../../../Domain/Models/UserLine/UserLine.model";
import {TUserOwner} from "../../../../Domain/Models/UserOwner/UserOwner.model";
import {TLine} from "../../../../Domain/Models/Line/Line.model";
import {useTranslation} from "react-i18next";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface ConsumptionsProps {
    sessionUser: TUserLine|TUserOwner
    sessionUserLinesData: TLine
}


const Consumptions: FC<ConsumptionsProps>= ({sessionUser, sessionUserLinesData}) => {

    const { t } = useTranslation();
    const [selectedLine, setSelectedLine] = useState(sessionUserLinesData[0].numberLine)
    const [isLoading, setIsLoading] = useState(false)
    const [totalConsumptionHistory, setTotalConsumptionHistory] = useState([])
    const [selectSegment, setSegment] = useState('Voice')

    useEffect(() => {
        const fetchData = async ()=> {
            setIsLoading(true)
            await obtainConsumptionHistory(selectSegment, selectedLine, sessionUser.userLoggedIn.accessToken).then(response => {
                setTotalConsumptionHistory(response)
            })
            setIsLoading(false)
        }

        fetchData().then()

    }, [selectedLine, selectSegment])

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="toolbar">
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <ListOfLines
                        dataList={sessionUserLinesData}
                        valueSelected={selectedLine} onListChange={setSelectedLine}/>
                </IonToolbar>

            </IonHeader>
            <IonContent fullscreen>
                <IonToolbar>
                    <IonSegment value={selectSegment} onIonChange={(e) => setSegment(e.detail.value)}>
                        <IonSegmentButton value="Voice">
                            {t('Consumptions.section-calls-title')}
                        </IonSegmentButton>
                        <IonSegmentButton value="Data">
                            {t('Consumptions.section-data-title')}
                        </IonSegmentButton>
                        <IonSegmentButton value="Sms">
                            {t('Consumptions.section-sms-title')}
                        </IonSegmentButton>
                    </IonSegment>
                </IonToolbar>

                <SegmentList
                    titleList={t('Consumptions.text-detail')}
                    dataConsumptions={totalConsumptionHistory}
                    show={selectSegment === "Voice"}/>
                <SegmentList
                    titleList={t('Consumptions.text-detail')}
                    dataConsumptions={totalConsumptionHistory}
                    show={selectSegment === "Data"}/>
                <SegmentList
                    titleList={t('Consumptions.text-detail')}
                    dataConsumptions={totalConsumptionHistory}
                    show={selectSegment === "Sms"}/>

            </IonContent>
            <Loading isLoading={isLoading}/>
        </IonPage>
    );
};

export default Consumptions;
