import {IonSelect, IonSelectOption} from "@ionic/react";
import './ListOfLines.css'
import {useTranslation} from "react-i18next";


export default function ListOfLines({dataList, valueSelected,onListChange}) {
    const { t } = useTranslation();
    return (<>
        <div className="select-lines">
            <IonSelect interface="popover" value={valueSelected} onIonChange={(e) => onListChange(e.detail.value)}>
                {dataList.map((e) => {
                        return (<IonSelectOption key={e.numberLine}
                                                 value={e.numberLine}>{t('ListOfLines.text')} {e.numberLine} </IonSelectOption>)
                    })
                }
            </IonSelect>
        </div>
    </>)
}
