import {
    IonAlert, IonButtons, IonContent, IonHeader, IonList, IonListHeader, IonMenuButton, IonPage, IonToolbar
} from "@ionic/react";
import Loading from "../../SharedComponents/Loading/Loading";
import ListOfLines from "../../SharedComponents/ListOfLines/ListOfLines";
import {useEffect, useState} from "react";
import ListServicesLines from "./ListServicesLines/ListServicesLines";
import {obtainAllServicesLine} from "../../../../Application/Lines/ObtainServicesLine/ObtainAllServicesLine";
import {changeServiceLine} from "../../../../Application/Lines/ChangeStateServiceLine/ChangeServiceLine";
import {useTranslation} from "react-i18next";

function titleToggle(t, name) {
    if (name === "roaming") return t('ServicesLines.text-option-roaming')
    if (name === "voicemail") return t('ServicesLines.text-option-voicemail')
}

export const ServicesLines = ({sessionUser, sessionUserLinesData}) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false)
    const [listServices, setListServices] = useState([])
    const [selectedLine, setSelectedLine] = useState(sessionUserLinesData[0].numberLine)
    const [serviceForwardingClick, setServiceForwardingClick] = useState(false)
    const [changeServiceClick, setChangeServiceClick] = useState()
    const [changeServiceForwarding, setChangeServiceForwarding] = useState()
    const [showAlert, setShowAlert] = useState(false)
    const [serviceClick, setServiceClick] = useState(false)

    let dataLineSelected = sessionUserLinesData.find(element => element.numberLine === selectedLine);

    useEffect(async function () {
        let isError = false
        setIsLoading(true)

        if (changeServiceClick !== undefined) {
            await changeServiceLine(dataLineSelected.numberLine, changeServiceClick, sessionUser.userLoggedIn.accessToken)
            setChangeServiceClick()
        }

        if (changeServiceForwarding !== undefined) {

            let numberForwarding = String(changeServiceForwarding.lineServices[0].number).length
            if (numberForwarding > 9 || numberForwarding < 9) {
                isError = true
                setShowAlert(t('ServicesLines.text-error-callforwarding'))
                setIsLoading(false)
            } else {
                await changeServiceLine(dataLineSelected.numberLine, changeServiceForwarding, sessionUser.userLoggedIn.accessToken)
                setChangeServiceForwarding()
            }
        }
        if (!isError) {
            await obtainAllServicesLine(selectedLine, dataLineSelected.rate.provider, sessionUser.userLoggedIn.accessToken).then(e => setListServices(e))
        }

        setIsLoading(false)
    }, [changeServiceClick, changeServiceForwarding, selectedLine])

    const updateStateServiceClick = (serviceClick, dataLineSelected) => {

        let auxDataServiceNew = {
            "providerId": dataLineSelected.rate.provider, "lineServices": [serviceClick]
        }

        setChangeServiceForwarding()
        setChangeServiceClick(auxDataServiceNew)
    }

    const updateForwardingServiceClick = (dataForm, serviceForwardingClick) => {
        if (dataForm !== undefined) serviceForwardingClick.number = dataForm.numberForwardingForm

        let auxDataServiceForwardingNew = {
            "providerId": dataLineSelected.rate.provider, "lineServices": [serviceForwardingClick]
        }
        setChangeServiceClick()
        setChangeServiceForwarding(auxDataServiceForwardingNew)
    }

    return (<IonPage>
        <IonHeader>
            <IonToolbar className="toolbar">
                <IonButtons slot="start">
                    <IonMenuButton/>
                </IonButtons>
                <ListOfLines dataList={sessionUserLinesData}
                    valueSelected={selectedLine} onListChange={setSelectedLine}/>
            </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
            <IonList lines="inset">
                <IonListHeader lines="inset" className="header-list">
                    <h6 className="list-header">{t('ServicesLines.page-title')} <strong>{selectedLine}</strong>
                    </h6>
                </IonListHeader>
                {listServices.map((element) => {
                    return (<ListServicesLines key={element.name} element={element} setServiceClick={setServiceClick}
                                               setServiceForwardingClick={setServiceForwardingClick}/>)
                })}
            </IonList>
        </IonContent>
        <Loading isLoading={isLoading} setIsLoading={setIsLoading}/>
        <IonAlert
            isOpen={serviceClick !== false}
            header={t('ServicesLines.text-alert-title')}
            message={t('ServicesLines.text-alert-body', { OnOrOff: (serviceClick.active ? t('ServicesLines.text-activate') : t('ServicesLines.text-deactivate')), nameService:  titleToggle(t, serviceClick.name), numberLine: selectedLine})}
            buttons={[t('ServicesLines.button-alert-no'), {
                text: t('ServicesLines.button-alert-yes'), handler: () => {
                    updateStateServiceClick(serviceClick, dataLineSelected)
                }
            }]}
            onDidDismiss={() => {
                setServiceClick(false)
            }}
        />
        <IonAlert
            isOpen={serviceForwardingClick !== false}
            header={t('ServicesLines.text-alert-title')}
            message={serviceForwardingClick.active ? t('ServicesLines.text-alert-body-forwarding') : t('ServicesLines.text-alert-body-confirm-forwarding', {numberLine: selectedLine})}
            buttons={[t('ServicesLines.button-alert-cancel'), {
                text: t('ServicesLines.button-alert-confirm'), handler: (dataForm) => {
                    updateForwardingServiceClick(dataForm, serviceForwardingClick)
                }
            }]}
            inputs={serviceForwardingClick.active ? [{
                name: 'numberForwardingForm', type: 'number', placeholder: t('ServicesLines.text-alert-input-placeholder')
            }] : []}
            onDidDismiss={() => {
                setServiceForwardingClick(false)
            }}
        />
        <IonAlert
            isOpen={showAlert !== false}
            header={t('ServicesLines.text-alert-title')}
            message={showAlert}
            buttons={[t('ServicesLines.button-alert-accept')]}
            onDidDismiss={() => {
                setShowAlert(false)
            }}
        />
    </IonPage>)
}