export const dataGraphBar = (dataLabel, dataBar) => {
    return {
        labels: dataLabel,
        datasets: [{
            backgroundColor: '#008AC7',
            borderColor: 'black',
            borderWidth: 1,
            data: dataBar,
        }]
    }
}

export const optionGraphBar = () => {
    return {
        responsive: true,
        animation: false,
        events: [],
        plugins: {
            legend: {
                display: false,
            }
        }
    }
}