import {
    IonAlert,
    IonButton,
    IonButtons, IonCardContent,
    IonCol,
    IonContent,
    IonHeader, IonLabel, IonListHeader,
    IonMenuButton,
    IonPage, IonRow, IonSelect, IonSelectOption,
    IonTitle,
    IonToolbar
} from '@ionic/react';
import './Invoices.css'
import {FC, useEffect, useState} from "react";
import { Browser } from '@capacitor/browser';
import Loading from "../../SharedComponents/Loading/Loading";
import {obtainInvoicesUserOwner} from "../../../../Application/UserOwner/ObtainInvoicesUserOwner";
import {TUserLine} from "../../../../Domain/Models/UserLine/UserLine.model";
import {TUserOwner} from "../../../../Domain/Models/UserOwner/UserOwner.model";
import {InvoicesNotFound} from "../../../../Domain/Services/UserOwner/InvoicesNotFound.exception";
import axios from "axios";
import {useTranslation} from "react-i18next";

function concatTextInvoice(t, element) {
    const months = [{month: "01", name: "Months.text-month-january"}, {month: "02", name:"Months.text-month-february"}, {month: "03", name:"Months.text-month-march"} ,{month: "04", name:"Months.text-month-april"}, {month: "05", name:"Months.text-month-may"}, {month: "06", name:"Months.text-month-june"}, {month: "07", name:"Months.text-month-july"}, {month: "08", name:"Months.text-month-august"}, {month: "09", name:"Months.text-month-september"},{month: "10", name:"Months.text-month-october"},{month: "11", name:"Months.text-month-november"},{month: "12", name:"Months.text-month-december"}]

    let found = months.find(o => o.month === element.month);
    return t('Invoices.text-select-invoice') + t(found.name) + " " + element.year;

}

interface InvoicesProps {
    sessionUser: TUserLine|TUserOwner
}

const Invoices: FC<InvoicesProps> = ({sessionUser}) => {

    const { t } = useTranslation();
    const [dataListInvoices, setDataListInvoices] = useState([])
    const [monthSelected, setMonthSelected] = useState()
    const [showInvoices, setShowInvoices] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [showAlert, setShowAlert] = useState(false);

    useEffect(function () {
        const fetchData = async () => {
            try {
                setIsLoading(true)

                await obtainInvoicesUserOwner(sessionUser.userLoggedIn.userName, sessionUser.userLoggedIn.accessToken).then(response => {
                    setDataListInvoices(response)
                })

                setIsLoading(false)
            }catch (e){
                if (e instanceof InvoicesNotFound) setShowInvoices(false)
                setIsLoading(false)
            }
        }
        fetchData().then()

    }, [])

    const handleLogin = async (e) => {
        e.preventDefault();
        await axios.get(monthSelected).then( e => { Browser.open({ url: monthSelected}) }).catch(function (error){
            setShowAlert(true)
        })
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="toolbar">
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle>{t('Invoices.page-title')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                {
                    showInvoices ?
                        formSelectInvoices(t, handleLogin, setMonthSelected, dataListInvoices, monthSelected) :
                        <IonListHeader lines="inset" className="header-list"><h6>{t('Invoices.text-subtitle-notinvoices')}</h6></IonListHeader>
                }
            </IonContent>
            <Loading isLoading={isLoading}/>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                cssClass='my-custom-class'
                header={t('Invoices.text-alert-title')}
                message={t('Invoices.text-alert-body')}
                buttons={[t('Invoices.text-alert-button-accept')]}
            />
        </IonPage>

    );
};

const formSelectInvoices = (t, handleLogin, setMonthSelected, dataListInvoices, monthSelected) => {
    return (<form noValidate onSubmit={handleLogin}>
        <IonCardContent>
            <IonLabel className="label-invoices">
                <h2>{t('Invoices.text-subtitle')}</h2>
            </IonLabel>
            <IonRow>
                <IonCol>
                    <IonSelect interface="popover"
                               onIonChange={(e) => setMonthSelected(e.detail.value)}
                               className="select-invoices">
                        {dataListInvoices.map((element, index) => {
                            return (<IonSelectOption key={index}
                                                     value={element.url}>{concatTextInvoice(t, element)}</IonSelectOption>)
                        })
                        }
                    </IonSelect>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol>
                    {monthSelected ?
                        <IonButton type="submit" expand="block" className="botton-actions" disabled={false}>{t('Invoices.button-download')}</IonButton> :
                        <IonButton type="submit" expand="block" className="botton-actions" disabled={true}>{t('Invoices.button-download')}</IonButton>}
                </IonCol>
            </IonRow>
        </IonCardContent>
    </form>)
}

export default Invoices;