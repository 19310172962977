import {
    IonTabs,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonLabel
} from '@ionic/react';
import {Route} from 'react-router';
import {homeOutline, barChartOutline, personOutline} from 'ionicons/icons';
import Home from "./Home/Home";
import Invoices from "./Invoices/Invoices";
import Consumptions from "./Consumptions/Consumptions";
import Account from "./Account/Account";
import SharedBonus from "./SharedBonus/SharedBonus";
import {useLocation} from "react-router-dom";
import './Dashboard.css';
import React from "react";
import {ServicesLines} from "./ServicesLines/ServicesLines";
import {useTranslation} from "react-i18next";

let listPages = [
    {
        title: 'Dashboard.page-home',
        tab: 'home',
        url: '/dashboard/home',
        icon: homeOutline,
        showLineUser: true
    },
    {
        title: 'Dashboard.page-consumptions',
        tab: 'consumptions',
        url: '/dashboard/consumptions',
        icon: barChartOutline,
        showLineUser: true
    },
    {
        title: 'Dashboard.page-account',
        tab: 'account',
        url: '/dashboard/account',
        icon: personOutline,
        showLineUser: false
    }
]

const Dashboard = ({sessionUser, sessionUserLinesData}) => {
    const { t } = useTranslation();
    const locationCurrent = useLocation();
    if (!sessionUser.userLoggedIn.isOwner) listPages = listPages.filter(onePage => onePage.showLineUser !== sessionUser.userLoggedIn.isOwner)

    return (
        <IonTabs>
            <IonRouterOutlet>
                <Route exact path="/dashboard/home" render={() => <Home sessionUser={sessionUser} sessionUserLinesData={sessionUserLinesData} />}/>
                <Route exact path="/dashboard/consumptions" render={() => <Consumptions sessionUser={sessionUser} sessionUserLinesData={sessionUserLinesData}/>}/>
                <Route exact path="/dashboard/account" render={() => <Account sessionUser={sessionUser}/>}/>
                <Route exact path="/dashboard/invoices" render={() => <Invoices sessionUser={sessionUser}/>}/>
                <Route exact path="/dashboard/shared-bonus" render={() => <SharedBonus sessionUser={sessionUser} sessionUserLinesData={sessionUserLinesData} />}/>
                <Route exact path="/dashboard/services-lines" render={() => <ServicesLines sessionUser={sessionUser} sessionUserLinesData={sessionUserLinesData} />}/>
            </IonRouterOutlet>

            <IonTabBar slot="bottom" className="tab-bar">
                {listPages.map(({title, url, icon, tab}) => {
                    return (
                        <IonTabButton key={url} tab={tab} href={url}
                                      className={locationCurrent.pathname === url ? 'tab-selected' : ''}>
                            <IonIcon icon={icon}/>
                            <IonLabel>{t(title)}</IonLabel>
                        </IonTabButton>
                    );
                })}

            </IonTabBar>
        </IonTabs>
    );
};

export default Dashboard;