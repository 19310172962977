import {
    apiMobileIpv6InformaticaRepository
} from "../../../Infrastructure/Repositories/ApiMobileIpv6Informatica/ApiMobileIpv6Informatica.repository";
import {TUserOwner} from "../../Models/UserOwner/UserOwner.model";
import {TLine} from "../../Models/Line/Line.model";
import {TDataBonus} from "../../Models/DataBonus/DataBonus.model";
import {TRate} from "../../Models/Rate/Rate.model";
import {Utils} from "../../Utils/Utils";
import {TUser} from "../../Models/User/User.model";
import {TInvoice} from "../../Models/Invoice/Invoice.model";
import {InvoicesNotFound} from "./InvoicesNotFound.exception";
import {TVoiceBonus} from "../../Models/VoiceBonus/VoiceBonus.model";
import {TAdditionalBonus} from "../../Models/AdditionalBonus/AdditionalBonus.model";

const getPersonalInformation = async (userLogged: TUser) => {

    const {data} = await apiMobileIpv6InformaticaRepository.getCustomerPersonalData(userLogged.userName, userLogged.accessToken)

    const objUserOwner: TUserOwner = {
        name: data.name,
        surName1: data.surname,
        surName2: data.surname2,
        identificationNumber: data.documentNumber,
        identificationType: data.documentType,
        email: data.email,
        userLoggedIn: userLogged
    }

    return objUserOwner
}

const getAllLines = async (userLogged: TUser) => {

    const {data} = await apiMobileIpv6InformaticaRepository.getCustomerAllLines(userLogged.userName, userLogged.accessToken)

    let linesCollection: TLine[] = data.map(element => {
        let objDataBonus: TDataBonus | null = null
        let objVoiceBonus: TVoiceBonus | null = null
        let objAdditionalBonuses: TAdditionalBonus | null = null

        if (element.dataBonus !== null) {
            objDataBonus = {
                id: element.dataBonus.id,
                name: element.dataBonus.name,
                megas: element.dataBonus.megas,
                minutes: element.dataBonus.minutes,
                price: Utils.calculatePriceIncludingIVA(element.dataBonus.price),
                provider: element.dataBonus.providerId,
                subscriptionDatetime: null,
                unSubscriptionDatetime: null
            }
        }

        if (element.voiceBonus !== null) {
            objVoiceBonus = {
                id: element.voiceBonus.id,
                name: element.voiceBonus.name,
                megas: element.voiceBonus.megas,
                minutes: element.voiceBonus.minutes,
                price: Utils.calculatePriceIncludingIVA(element.voiceBonus.price),
                provider: element.voiceBonus.providerId,
                subscriptionDatetime: null,
                unSubscriptionDatetime: null
            }
        }

        let currentDate = new Date(new Date().setHours(0,0,0,0))
        let additionalBonusesActive = element.additionalBonuses.filter(element => element.unSubscriptionDatetime === null || Utils.formatStringToDate(element.unSubscriptionDatetime) >= currentDate);

        objAdditionalBonuses = getActiveAdditionalBonuses(additionalBonusesActive)

        let objRate: TRate = {
            id: element.rate.id,
            price: Utils.calculatePriceIncludingIVA(element.rate.price),
            megas: Number(element.rate.megas),
            minutes: Number(element.rate.minutes),
            name: element.rate.name,
            provider: element.rate.providerId,
            coverage: element.rate.coverage,
            isShared: element.rate.isShared
        }

        let objLine: TLine = {
            numberLine: element.id,
            customerLine: element.customerId,
            sharedBonusPercentage: element.sharedBonusPercentage,
            rate: objRate,
            dataBonus: objDataBonus,
            voiceBonus: objVoiceBonus,
            additionalBonuses: objAdditionalBonuses

        }

        return objLine
    });

    return linesCollection
}

const getActiveAdditionalBonuses = (additionalBonusesActive): TAdditionalBonus => {

    let sumMinutes = 0;
    let sumMegabytes = 0;
    let collectionAdditionalBonuses: TVoiceBonus[] | TDataBonus[] = additionalBonusesActive.map(element => {
        let objBonus: TDataBonus | TVoiceBonus = {
            id: element.bonus.id,
            price: Utils.calculatePriceIncludingIVA(element.bonus.price),
            name: element.bonus.name,
            megas: String(element.bonus.megas).length > 0 ? element.bonus.megas : null,
            minutes: String(element.bonus.minutes).length > 0 ? element.bonus.minutes : null,
            provider: element.bonus.providerId,
            subscriptionDatetime: element.bonus.subscriptionDatetime,
            unSubscriptionDatetime: element.bonus.unSubscriptionDatetime
        }

        if (String(objBonus.megas).length > 0) sumMegabytes = sumMegabytes + Number(objBonus.megas)
        if (String(objBonus.minutes).length > 0) sumMinutes = sumMinutes + Number(objBonus.minutes)

        return objBonus
    })

    return {
        bonus: collectionAdditionalBonuses,
        totalmegabytes: sumMegabytes,
        totalminutes: sumMinutes
    };
}

const getInvoices = async (sessionUsername: string, sessionAccesstoken: string) => {
    const {data, status} = await apiMobileIpv6InformaticaRepository.getInvoices(sessionUsername, sessionAccesstoken)

    if (data.length === 0) throw new InvoicesNotFound(status, 'Invoices not found')

    let collectionInvoices: TInvoice[] = data.map((element) => {
        let objInvoice: TInvoice = {
            customer: element.id,
            month: element.month,
            year: element.year,
            url: element.url
        }

        return objInvoice
    })

    return collectionInvoices
}

export const UserOwnerService = {
    getPersonalInformation,
    getAllLines,
    getInvoices
}
