import {
    apiMobileIpv6InformaticaRepository
} from "../../../Infrastructure/Repositories/ApiMobileIpv6Informatica/ApiMobileIpv6Informatica.repository";
import {TUser} from "../../Models/User/User.model";
import {InvalidUserCredentials} from "./InvalidUserCredentials.exception";

const loginUser = async (username: string, password: string) => {

    let {data, status} = await apiMobileIpv6InformaticaRepository.login(username, password)

    if (status >= 400 && data.error.includes('credentials')) throw new InvalidUserCredentials(status, data.error)

    const objUser: TUser = {userName: data.user.username, accessToken: data.accessToken, isOwner: data.user.isOwner}

    return objUser
}

export const UserService = {
    loginUser
}