import {
    IonAlert,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem, IonLabel,
    IonList, IonMenuButton, IonNote,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import './Account.css'
import {TUserOwner} from "../../../../Domain/Models/UserOwner/UserOwner.model";
import {FC} from "react";
import {TUserLine} from "../../../../Domain/Models/UserLine/UserLine.model";
import {useState} from "react";
import {useTranslation} from "react-i18next";

interface AccountProps {
    sessionUser: TUserOwner|TUserLine
}

const Account: FC<AccountProps> = ({sessionUser}) => {

    const { t } = useTranslation();
    const [showAlert, setShowAlert] = useState(false);

    return (
        <IonPage className="account-page">
            <IonHeader>
                <IonToolbar className="toolbar">
                    <IonButtons slot="start">
                        <IonMenuButton></IonMenuButton>
                    </IonButtons>
                    <IonTitle>{t('Account.page-title')}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className="ion-padding-top ion-text-center">
                    <img src="./assets/img/logo_app_2022_544px.png" alt="avatar"/>
                    <IonList lines="inset">
                        <IonItem>
                            <IonLabel className="label-text ion-text-wrap">{t('Account.text-fullname')}</IonLabel>
                            <IonNote slot="end" className="label-text-important">{sessionUser.name + " " +sessionUser.surName1 + " " +sessionUser.surName2}</IonNote>
                        </IonItem>
                        <IonItem>
                            <IonLabel className="label-text ion-text-wrap">{t('Account.text-identification-type')}</IonLabel>
                            <IonNote slot="end" className="label-text-important">{sessionUser.identificationType}</IonNote>
                        </IonItem>
                        <IonItem>
                            <IonLabel className="label-text ion-text-wrap">{t('Account.text-identification-number')}</IonLabel>
                            <IonNote slot="end" className="label-text-important">{sessionUser.identificationNumber}</IonNote>
                        </IonItem>
                        <IonItem>
                            <IonLabel className="label-text ion-text-wrap">{t('Account.text-email')}</IonLabel>
                            <IonNote slot="end" className="label-text-important">{sessionUser.email}</IonNote>
                        </IonItem>
                    </IonList>
                </div>
                <div className="div-botton-baja">
                    <IonButton onClick={() => setShowAlert(true)} className="botton-baja">{t('Account.button-cancel-service')}</IonButton>
                </div>
            </IonContent>
            <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                cssClass='my-custom-class'
                header={t('Account.text-alert-title')}
                message={t('Account.text-alert-body')}
                buttons={[t('Account.text-alert-button-accept')]}
            />
        </IonPage>
    );
}

export default Account