import {
    apiMobileIpv6InformaticaRepository
} from "../../../Infrastructure/Repositories/ApiMobileIpv6Informatica/ApiMobileIpv6Informatica.repository";
import {TBonusShared} from "../../Models/BonusShared/BonusShared.model";
import {Utils} from "../../Utils/Utils";
import {ErrorModifyConsumptionPercentage} from "./ErrorModifyConsumptionPercentage.exception";
import i18n from '../../../Infrastructure/Translations/I18next/i18next.config';


const getLineSharingBonus = async (phoneNumber: string, sessionAccesstoken: string) => {

    let {data} = await apiMobileIpv6InformaticaRepository.getLineSharedBonus(phoneNumber, sessionAccesstoken)

    const objSharedBonus: TBonusShared = {
        phoneNumber: data.phoneNumber,
        customer: data.customerId,
        percentConsumptionOnSharedBonus: data.percentConsumptionOnSharedBonus,
        totalGb: Utils.convertMegabytesToGigabytes(data.total)
    }

    return objSharedBonus
}

const changeSharingBonus = async (phoneNumber: string, body: any, sessionAccesstoken: string) => {
    let {status,data} = await apiMobileIpv6InformaticaRepository.changeConsumptionPercentage(phoneNumber, body, sessionAccesstoken)

    if (status >= 500 && data.message.includes('El estado del activo no permite modificar perfil de consumo')) throw new ErrorModifyConsumptionPercentage(status, i18n.t('SharedBonus.text-error-change-percentage'))

    return true
}

export const BonusSharedService = {
    getLineSharingBonus,
    changeSharingBonus
}