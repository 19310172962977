const convertBytesToMegabytes = (bytes: number): number => {
    return (bytes / Math.pow(1024, 2));
}

const convertMegabytesToGigabytes = (megabytes: number): number => {
    return (megabytes / Math.pow(1024, 1));
}

const calculatePriceIncludingIVA = (priceWithoutIVA: number): string => {
    return (priceWithoutIVA * 1.21).toFixed(2)
}

const getStartDateCurrentMonth = () => {
    const dateCurrent = new Date();
    return formatDate(new Date(dateCurrent.getFullYear(), dateCurrent.getMonth(), 1));
};

const getEndDateCurrentMonth = () => {
    const dateCurrent = new Date();
    return formatDate(new Date(dateCurrent.getFullYear(), dateCurrent.getMonth() + 1, 0));
};

const formatDate = (date) => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${date.getFullYear()}-${(month < 10 ? '0' : '').concat(month)}-${(day < 10 ? '0' : '').concat(day)}`;
};

const formatStringToDate = (date) => {
    let [day, month, year] = date.split('/');
    return new Date(year, month - 1, day);
}

export const Utils = {
    convertBytesToMegabytes,
    convertMegabytesToGigabytes,
    calculatePriceIncludingIVA,
    getStartDateCurrentMonth,
    getEndDateCurrentMonth,
    formatDate,
    formatStringToDate
}