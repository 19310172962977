import {IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {Route} from 'react-router-dom';
import {Redirect} from "react-router";
import {App as CapacitorApp} from '@capacitor/app';
import Login from "./Login/Login";
import React, {useState} from "react";
import Dashboard from "./Dashboard/Dashboard";
import RecoveryPassword from "./Login/RecoveryPassword/RecoveryPassword";
import SignUp from "./Login/SignUp/SignUp";
import SignOff from "./Dashboard/SignOff/SignOff";

/* Core CSS required for Ionic Components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './App.css';
import Menu from "./SharedComponents/Menu/Menu";
import {TUserOwner} from "../../Domain/Models/UserOwner/UserOwner.model";
import {TUserLine} from "../../Domain/Models/UserLine/UserLine.model";
import {TLine} from "../../Domain/Models/Line/Line.model";

setupIonicReact();
sessionStorage.clear();
CapacitorApp.addListener('backButton', () => {
    CapacitorApp.exitApp();
});


const App = () => {

    const [isAuthenticated, setIsAuthenticated] = useState(false)

    let sessionUser: TUserLine|TUserOwner
    let sessionUserLinesData: TLine
    if(isAuthenticated){
        sessionUserLinesData = JSON.parse(sessionStorage.getItem(process.env.REACT_APP_SESSION_USER_LINES_DATA))
        sessionUser = JSON.parse(sessionStorage.getItem(process.env.REACT_APP_SESSION_USER_PERSONAL_DATA))
    }

    return (
        <IonApp>
            <IonReactRouter>
                <IonSplitPane contentId="main">
                    {isAuthenticated ? <Menu sessionUser={sessionUser}/> : ''}
                    <IonRouterOutlet id="main">
                        <Route path="/" exact
                               render={() => !isAuthenticated ? <Login setIsAuthenticated={setIsAuthenticated}/> :
                                   <Redirect exact path="/" to="/dashboard/home"/>}/>
                        <Route path="/dashboard/:pageId" exact render={() => isAuthenticated ? <Dashboard sessionUser={sessionUser} sessionUserLinesData={sessionUserLinesData}/> :
                            <Redirect exact path="/dashboard/:pageId" to="/"/>}/>
                        <Route path="/:otherId" exact render={() => <Redirect to="/"/>}/>
                        <Route path="/sign-up" exact render={() => <SignUp/>}/>
                        <Route path="/recovery-password" exact render={() => <RecoveryPassword/>}/>
                        <Route path="/logout" exact render={() => <SignOff/>}/>
                    </IonRouterOutlet>
                </IonSplitPane>
            </IonReactRouter>
        </IonApp>
    );
};

export default App;
