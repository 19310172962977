import {
    IonCardContent,
    IonCol,
    IonGrid,
    IonItem, IonItemDivider,
    IonLabel,
    IonList, IonNote,
    IonRow,
} from "@ionic/react";

import "./SummarySelectedLine.css"
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {useEffect, useState} from "react";
import Loading from "../../../SharedComponents/Loading/Loading";
import {obtainSummaryConsumptions} from "../../../../../Application/Lines/ObtainSummaryConsumptions/ObtainConsumptions";
import {useTranslation} from "react-i18next";

function calculatePercentageMegabytesConsumedBonusShared(consumption, line) {

    let totalConsumptionData = 0;

    if (typeof consumption.dataResume !== "undefined") totalConsumptionData = consumption.dataResume.totalDataConsumption;
    if (line.sharedBonusPercentage === 0) return parseInt(line.sharedBonusPercentage)
    if (totalConsumptionData === 0) return parseInt(line.sharedBonusPercentage)


    let megabytes = totalConsumptionData
    let megabytesToConsume = 0;

    let megabytesBonusShared = (parseInt(line.sharedBonusPercentage) * parseInt(line.dataBonus.megas)) / 100
    if (megabytes > megabytesBonusShared) {
        megabytesToConsume = megabytes - megabytesBonusShared
    } else {
        megabytesToConsume = megabytesBonusShared - megabytes
    }

    let percentageMegabytesToConsume = (megabytesToConsume / megabytesBonusShared) * 100

    return Math.trunc(percentageMegabytesToConsume)
}

function calculatePercentageMegabytesConsumed(consumption, line) {

    if (typeof consumption !== "undefined") {
        if (line.rate.megas === "0" && !line.rate.name.includes("COMPARTIDA") && line.additionalBonuses.totalmegabytes === 0) return 0
        if (line.rate.name.includes("COMPARTIDA") && line.bonus !== null) return calculatePercentageMegabytesConsumedBonusShared(consumption, line)

        let lineRateMegas = parseInt(line.rate.megas) + line.additionalBonuses.totalmegabytes;

        if (lineRateMegas === 0) return 0
        let totalConsumptionData = 0;
        if (typeof consumption.dataResume !== "undefined") totalConsumptionData = consumption.dataResume.nonAccumulatedConsumedData;

        let megabytes = totalConsumptionData
        let megabytesToConsume = 0;

        if (megabytes > lineRateMegas) {
            megabytesToConsume = megabytes - lineRateMegas
        } else {
            megabytesToConsume = lineRateMegas - megabytes
        }

        let percentageMegabytesToConsume = (megabytesToConsume / lineRateMegas) * 100

        return Math.trunc(percentageMegabytesToConsume)
    }
}

function convertToGigabytes(megabytes) {
    return (megabytes / Math.pow(1024, 1)).toFixed(2);
}

function calculateMegabytesConsumed(consumption) {
    if (typeof consumption !== "undefined") {
        let megabytes = 0
        if (typeof consumption.dataResume !== "undefined") megabytes = consumption.dataResume.totalDataConsumption
        return convertToGigabytes(megabytes);
    }

}

function calculateMegabytesToConsume(consumption, line) {
    if (typeof consumption !== "undefined") {
        let dataAccumulated = 0;
        let megabytes = 0;
        if (typeof consumption.dataResume !== "undefined") dataAccumulated = consumption.dataResume.dataAccumulated
        let sumDataAndDataAccumulated = parseInt(line.rate.megas) + dataAccumulated + line.additionalBonuses.totalmegabytes
        if (typeof consumption.dataResume !== "undefined") megabytes = consumption.dataResume.totalDataConsumption
        if (typeof consumption.dataResume !== "undefined" && dataAccumulated === 0) megabytes = consumption.dataResume.nonAccumulatedConsumedData
        let megabytesToConsume = 0

        if (typeof consumption.dataResume !== "undefined" && dataAccumulated > 0) return convertToGigabytes(sumDataAndDataAccumulated)

        if (megabytes > sumDataAndDataAccumulated) {
            megabytesToConsume = megabytes - sumDataAndDataAccumulated
        } else {
            megabytesToConsume = sumDataAndDataAccumulated - megabytes
        }

        return convertToGigabytes(megabytesToConsume);
    }
}

function calculatePercentageMinutesConsumed(t, consumption, line, showPercent) {
    if (typeof consumption !== "undefined") {
        if (parseInt(line.rate.minutes) >= 3000 && showPercent) return t('Home.circle-progressbar-calls-unlimited')
        if (parseInt(line.rate.minutes) >= 3000 && !showPercent) return 100

        let lineRateMinutes = parseInt(line.rate.minutes) + line.additionalBonuses.totalminutes;
        let percentageMinutesToConsume = 0;
        let minutesToConsume = lineRateMinutes - ((consumption.voice / 60) * 10) / 10
        if (lineRateMinutes > 0) percentageMinutesToConsume = (minutesToConsume / lineRateMinutes) * 100
        if (showPercent) return Math.trunc(percentageMinutesToConsume) + "%"

        return Math.trunc(percentageMinutesToConsume)
    }

}

export default function SummarySelectedLine({line, data, sessionUser}) {

    let selectedLineData = data.find(element => element.numberLine === line)
    const {t} = useTranslation();
    const [summaryConsumptions, setSummaryConsumptions] = useState()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(function () {
        const fetchConsumptions = async () => {
            setIsLoading(true)
            await obtainSummaryConsumptions(line, selectedLineData.rate.provider, sessionUser.userLoggedIn.accessToken).then(r => {
                setSummaryConsumptions(r)
            })
            setIsLoading(false)
        }

        fetchConsumptions().then()

    }, [line])

    return (
        <>
            <IonCardContent>
                <IonList lines="none">
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <CircularProgressbar
                                    value={calculatePercentageMegabytesConsumed(summaryConsumptions, selectedLineData)}
                                    text={
                                        <tspan>
                                            <tspan x="50" y="45">{t('Home.circle-progressbar-data')}</tspan>
                                            <tspan x="50"
                                                   y="60">{calculatePercentageMegabytesConsumed(summaryConsumptions, selectedLineData)}%
                                            </tspan>
                                        </tspan>
                                    }
                                    strokeWidth={5}
                                    counterClockwise={true}
                                    styles={
                                        buildStyles({
                                            textSize: "10px",
                                            pathColor: "#008AC7",
                                            trailColor: "#C5E0EA",
                                            textColor: "#00467B"
                                        })}
                                />
                            </IonCol>
                            <IonCol>
                                <CircularProgressbar
                                    value={calculatePercentageMinutesConsumed(t, summaryConsumptions, selectedLineData, false)}
                                    text={
                                        <tspan>
                                            <tspan x="50" y="45">{t('Home.circle-progressbar-calls')}</tspan>
                                            <tspan x="50"
                                                   y="60">{calculatePercentageMinutesConsumed(t, summaryConsumptions, selectedLineData, true)}</tspan>
                                        </tspan>
                                    }
                                    strokeWidth={5}
                                    counterClockwise={true}
                                    styles={buildStyles({
                                        textSize: "10px",
                                        pathColor: "#008AC7",
                                        trailColor: "#C5E0EA",
                                        textColor: "#00467B"
                                    })}
                                />
                            </IonCol>

                        </IonRow>
                    </IonGrid>
                    <IonItemDivider className="divider-summary-selected-line"><IonLabel className="divider-label-summary-selected-line ion-text-wrap">{t('Home.text-data-resume')}</IonLabel></IonItemDivider>
                    <IonItem>
                        <IonLabel className="label-text ion-text-wrap">{t('Home.text-used-data')}</IonLabel>
                        <IonNote className="label-text-important"
                                 slot="end">{calculateMegabytesConsumed(summaryConsumptions)} GB.</IonNote>
                    </IonItem>
                    {(selectedLineData.rate.name.includes("COMPARTIDA") && selectedLineData.bonus !== null) ? itemDataShared(t, selectedLineData) : itemDataRemaining(t, summaryConsumptions, selectedLineData)}
                    {(typeof summaryConsumptions !== 'undefined' && summaryConsumptions.dataResume.dataAccumulated > 0) ? itemDataAccumulation(t, summaryConsumptions.dataResume.dataAccumulated) : ''}
                    <IonItem>
                        <IonLabel className="label-text ion-text-wrap">{t('Home.text-data-plan')}</IonLabel>
                        <IonNote className="label-text-important" slot="end">{<span
                            className="rate-gb">{selectedLineData.rate.name.includes("COMPARTIDA") && selectedLineData.dataBonus !== null ? convertToGigabytes(selectedLineData.dataBonus.megas) : convertToGigabytes(parseInt(selectedLineData.rate.megas) + selectedLineData.additionalBonuses.totalmegabytes)}</span>} GB.</IonNote>
                    </IonItem>
                    <IonItemDivider className="divider-summary-selected-line"><IonLabel className="divider-label-summary-selected-line ion-text-wrap">{t('Home.text-phone-plan')}</IonLabel></IonItemDivider>
                    <IonItem>
                        <IonLabel
                            className="label-text ion-text-wrap">{selectedLineData.rate.name.includes("COMPARTIDA") && selectedLineData.dataBonus !== null ? selectedLineData.dataBonus.name : selectedLineData.rate.name}</IonLabel>
                        <IonNote className="label-text-important"
                                 slot="end">{selectedLineData.rate.name.includes("COMPARTIDA") && selectedLineData.dataBonus !== null ? selectedLineData.dataBonus.price : selectedLineData.rate.price} € {t('Home.text-vat')}</IonNote>
                    </IonItem>
                    <IonItemDivider className="divider-summary-selected-line"><IonLabel className="divider-label-summary-selected-line ion-text-wrap">{t('Home.text-bonus-additionals')}</IonLabel></IonItemDivider>
                    {selectedLineData.additionalBonuses.bonus.length > 0 ? selectedLineData.additionalBonuses.bonus.map((element, index) => itemAdditionalBonuses(t, element, index))
                        : <IonItem><IonLabel className="label-text ion-text-wrap">{t('Home.text-additional-bonus-not-contracted')}</IonLabel></IonItem>}
                    <IonItemDivider className="divider-summary-selected-line"><IonLabel className="divider-label-summary-selected-line ion-text-wrap">{t('Home.text-other-data-interest')}</IonLabel></IonItemDivider>
                    <IonItem>
                        <IonLabel className="label-text">{t('Home.text-additional-fees')}</IonLabel>
                        <IonNote className="label-text-important"
                                 slot="end">{(typeof summaryConsumptions !== 'undefined') ? summaryConsumptions.additionalConsumption : ''} € {t('Home.text-vat')}</IonNote>
                    </IonItem>
                    <IonItem>
                        <IonLabel className="label-text ion-text-wrap">{t('Home.text-network')}</IonLabel>
                        <IonNote className="label-text-important"
                                 slot="end"><strong>{selectedLineData.rate.coverage}</strong></IonNote>
                    </IonItem>
                </IonList>
            </IonCardContent>
            <Loading isLoading={isLoading}/>
        </>
    )
}

const itemDataAccumulation = (t, dataAccumulation) => {

    return (<>
            <IonItem>
                <IonLabel className="label-text ion-text-wrap">{t('Home.text-data-accumulated')}</IonLabel>
                <IonNote className="label-text-important"
                         slot="end"><strong>{convertToGigabytes(dataAccumulation)}</strong> GB.</IonNote>
            </IonItem>
        </>
    )
}

const itemDataRemaining = (t, totalConsumption, selectedLineData) => {

    return (<>
            <IonItem>
                <IonLabel className="label-text ion-text-wrap">{t('Home.text-unused-data')}</IonLabel>
                <IonNote className="label-text-important"
                         slot="end"><strong>{calculateMegabytesToConsume(totalConsumption, selectedLineData)}</strong> GB.</IonNote>
            </IonItem>
        </>
    )
}
const itemDataShared = (t, selectedLineData) => {
    return (<>
            <IonItem>
                <IonLabel className="label-text ion-text-wrap">{t('Home.text-data-assigned')}</IonLabel>
                <IonNote className="label-text-important"
                         slot="end"><strong>{convertToGigabytes((parseInt(selectedLineData.sharedBonusPercentage) * parseInt(selectedLineData.dataBonus.megas)) / 100)}</strong> GB.</IonNote>
            </IonItem>
        </>
    )
}

const itemAdditionalBonuses = (t, selectedLineData, index) => {
    return (
        <IonItem key={index}>
            <IonLabel
                className="label-text ion-text-wrap">{selectedLineData.name}</IonLabel>
            <IonNote className="label-text-important"
                     slot="end">{selectedLineData.price} € {t('Home.text-vat')}</IonNote>
        </IonItem>
    )
}