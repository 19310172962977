import {
    apiMobileIpv6InformaticaRepository
} from "../../../Infrastructure/Repositories/ApiMobileIpv6Informatica/ApiMobileIpv6Informatica.repository";
import {TConsumptionSummary} from "../../Models/Consumptions/ConsumptionSummary.model";
import {Utils} from "../../Utils/Utils";
import {TConsumption} from "../../Models/Consumptions/Consumption.model";
import {TLineService} from "../../Models/LineService/LineService.model";

export const getSummaryConsumptionSelectedLine = async (selectedLine: string, lineProvider: string, sessionAccesstoken: string) => {
    let {data} = await apiMobileIpv6InformaticaRepository.getSummaryLineConsumption(selectedLine, lineProvider, sessionAccesstoken)

    const objConsumptionSummary: TConsumptionSummary = {
        phoneNumber: data.phoneNumber,
        sms: data.sms,
        data: Utils.convertBytesToMegabytes(data.data),
        dataResume: {
            totalDataConsumption: Utils.convertBytesToMegabytes(data.dataResume.totalDataConsumption),
            dataAccumulated: Utils.convertBytesToMegabytes(data.dataResume.dataAccumulated),
            nonAccumulatedConsumedData: Utils.convertBytesToMegabytes(data.dataResume.NonAccumulatedConsumedData)
        },
        voice: data.voice,
        additionalConsumption: Utils.calculatePriceIncludingIVA(data.additionalConsumption)
    }

    return objConsumptionSummary
}

export const getConsumptionHistoryByDate = async (requestBody: object, selectedLine: string, sessionAccesstoken: string) => {
    let {data} = await apiMobileIpv6InformaticaRepository.getConsumptionsHistory(requestBody, selectedLine, sessionAccesstoken)

    let consumptionCollection: TConsumption[] = data.map(element => {
        let objConsumption: TConsumption = {
            phoneNumber: element.id,
            date: element.date,
            totalConsumption: element.typeConsumption === 'Data' ? Utils.convertBytesToMegabytes(element.totalConsumption) : element.totalConsumption,
            typeConsumption: element.typeConsumption
        }
        return objConsumption
    })

    return consumptionCollection
}

export const getAllServicesSelectedLine = async (phoneNumber, provider, sessionAccesstoken) => {
    let {data} = await apiMobileIpv6InformaticaRepository.getLinesServices(phoneNumber, provider, sessionAccesstoken)

    let lineServicesCollection: TLineService[] = data.lineServices.map(e => {
        let objLineService: TLineService = {
            name: e.name,
            active: e.active,
            number: e.number
        }
        return objLineService
    })

    return lineServicesCollection
}

export const changeServiceSelectedLine = async (phoneNumber: string, body: any, sessionAccesstoken: string) => {
    let {data} = await apiMobileIpv6InformaticaRepository.changeLinesService(phoneNumber, body, sessionAccesstoken)
}
